import Vue from 'vue';
import { extend } from 'vee-validate';
import './plugins/base';
import Router from 'vue-router';
import vuescroll from 'vuescroll';
import VuetifyDialog from 'vuetify-dialog';
import dotenv from 'dotenv';
import storage from './common/storage';
import store from './store';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import 'vuetify-dialog/dist/vuetify-dialog.css';
import * as rules from './common/veeValidate'; // TO-DO

if (process.env.NODE_ENV === 'production' && typeof window !== 'undefined' && process.env.VUE_APP_BASE_URL === 'https://chain.hsnc.co.kr') {
  console.log = () => {};
  console.error = () => {};
}

dotenv.config();
Vue.config.productionTip = false;
console.log('process.env::', process.env);
console.log('VERSION : 1.0.1');

Vue.use(VuetifyDialog, {
  context: {
    vuetify,
  },
});
// Vue.prototype.$axios = api;
// setupInterceptor(storage);
const EventBus = new Vue();
Vue.prototype.$EventBus = EventBus;
Vue.use(vuescroll, {
  ops: {
    bar: {
      background: '#d8d8d8',
      keepShow: true,
      mousedownStep: 100,
      size: '8px',
    },
    rail: {
      gutterOfEnds: '4px',
      gutterOfSide: '4px',
    },
  },
  name: 'vuescroll',
});

// vee-validate rule 및 다국어 등록
Object.keys(rules).forEach((key) => {
  extend(key, rules[key]);
});

Vue.use(Router);
const routes = [
  {
    path: '/',
    redirect: '/web/login',
    meta: { unauthorized: true },
  },
  {
    path: '/web',
    redirect: '/web/login',
    meta: { unauthorized: true },
  },
  {
    path: '/web/login',
    component: () => import('@/views/Login.vue'),
    meta: { unauthorized: true },
  },
  {
    path: '/web/login/slo',
    component: () => import('@/views/Auth.vue'),
    meta: { unauthorized: true },
  },
  {
    name: 'main',
    path: '/web/main',
    component: () => import('@/views/Index.vue'),
    meta: { unauthorized: true },
    props: true,
  },
  {
    path: '/web/notsupport',
    name: 'notsupport',
    component: () => import('@/views/Notsupport.vue'),
    meta: { unauthorized: false },
  },
  {
    path: '/web/errorpage',
    name: 'errorpage',
    component: () => import('@/views/ErrorPage.vue'),
    meta: { unauthorized: false },
  },
];
const router = new Router({
  mode: 'history',
  routes,
});

function init() {
  new Vue({
    vuetify,
    router,
    store,
    storage,
    render: (h) => h(App),
  }).$mount('#app');
}

init();
