export default {
  setItem(key, value, storage = window.sessionStorage) {
    storage.setItem(key, value);
  },
  getItem(key, storage = window.sessionStorage) {
    return storage.getItem(key);
  },
  removeItem(key, storage = window.sessionStorage) {
    return storage.removeItem(key);
  },
  getAccessToken() {
    return this.getItem('access_token');
  },
  getToken() {
    return {
      accessToken: this.getItem('access_token'),
    };
  },
  setToken(data) {
    this.setItem('access_token', data.token);
  },
  removeToken() {
    this.removeItem('access_token');
  },
  getRefreshToken() {
    return {
      refreshToken: this.getItem('refresh_token'),
    };
  },
  setRefreshToken(data) {
    this.setItem('refresh_token', data.refreshToken);
  },
  removeRefreshToken() {
    this.removeItem('refresh_token');
  },
};
