export const getByteLength = (str = '') => {
  let byte = 0;

  // 예외처리 추가
  if (str === '' || str === undefined || str === null) return byte;

  for (let i = 0; i < str.length; ++i) {
    byte += (str.charCodeAt(i) > 127) ? 3 : 1;
  }
  return byte;
};

export const byteLengthCheck = (str, length = 4000) => getByteLength(str) <= length;
