<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
// import Login from '@/views/Login';

export default {
  name: 'App',

  components: {
    // Login,
  },

  data: () => ({
    //
  }),
};
</script>
<style lang="scss">
@import '@/assets/scss/index.scss';
</style>
