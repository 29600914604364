import Vue from 'vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import {
  required as Required,
  digits as Digits,
  email as Email,
  min as Min,
  max as Max,
  regex as Regex,
  confirmed as Confirmed,
  integer as Integer,
  between as Between,
} from 'vee-validate/dist/rules';
import { byteLengthCheck } from './util/byteCheck';

export const {
  fieldRequired,
  checkDigits = { ...Digits },
  checkEmail = { ...Email },
  checkMax = { ...Max },
  checkRegex = { ...Regex },
  checkInt = { ...Integer },
  checkBtwn = { ...Between },
  digits = Digits,
  min = Min,
  confirmed = Confirmed,
  required = Required,
  maxByteLength,
  notEquals,
  exceededAmt,
  datePickerRangeValid,
  rangeRequired,
  editorRequired,
  checkDuplicate,
} = {
  editorRequired: {
    validate(value) {
      const content = value.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, '').trim();
      return !!content;
    },
  },
  rangeRequired: {
    validate(value) {
      const [from, to] = value;
      return (!!from && !!to);
    },
  },
  fieldRequired: {
    ...Required,
  },
  maxByteLength: {
    validate(value, { length }) {
      return byteLengthCheck(value, length);
    },
    params: ['length'],
  },
  notEquals: {
    validate(value, { target }) {
      return value !== target;
    },
    params: ['target'],
  },
  exceededAmt: {
    validate(value, { businessAmt }) {
      return businessAmt >= 0;
    },
    params: ['businessAmt'],
  },
  datePickerRangeValid: {
    validate(value, {
      targetStartDate, targetEndDate,
    }) {
      let arr = [];
      if (Array.isArray(value)) {
        arr = value;
      } else {
        arr = value.split(' ~ ');
      }

      const condition = targetStartDate <= arr[0] && arr[0] <= targetEndDate;
      const condition2 = targetStartDate <= arr[1] && arr[1] <= targetEndDate;
      const condition3 = arr[0] <= arr[1];
      // console.log(condition && condition2 && condition3);

      return condition && condition2 && condition3;
    },
    getMessage(field) {
      console.log(field);
      // will be added to default locale messages.
      // Returns a message.
    },
    params: ['targetStartDate', 'targetEndDate'],
  },
  checkDuplicate: {
    validate(value, args) {
      if (args.filter((o) => o === value).length >= 2) {
        return false;
      }
      return true;
    },
    getMessage() {
    },
  },
};

Vue.component('validation-observer', ValidationObserver);
Vue.component('validation-provider', ValidationProvider);
