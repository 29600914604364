<template>
  <v-dialog v-model="show" v-bind="dialogOptions">
    <v-card v-bind="cardOptions">
      <v-card-title>
        <slot name="title">
          {{ title }}
        </slot>
        <v-btn
          v-if="showSave"
          depressed
          rounded
          color="color_blue_3"
          @click="save"
          class="font-weight-regular white--text f13 ml-2"
        >
          <v-icon class="mr-1 f14"> icon-hw_save </v-icon>
          현재버전 적용
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="showClose" @click="show = false" icon>
          <v-icon class="color_grey_1--text f16">icon-hw_close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <slot name="content" class="">
          content slot 영역을 작성하세요.
        </slot>
      </v-card-text>
      <v-card-actions v-if="showFooter">
        <slot name="footer">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="show = false">
            닫기
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { merge } from 'lodash';

export default {
  props: {
    value: Boolean,
    title: {
      type: String,
      default: 'Modal',
    },
    // v-dialog options
    fullscreen: Boolean,
    scrollable: Boolean,
    persistent: {
      type: Boolean,
      default: true,
    },
    maxWidth: {
      type: [String, Number],
      default: 800,
    },
    width: {
      type: [String, Number],
      default: 'auto',
    },
    contentClass: null,
    retainFocus: {
      type: Boolean,
      default: true,
    },
    // v-card options
    loading: Boolean,
    showClose: {
      type: Boolean,
      default: true,
    },
    showSave: {
      type: Boolean,
      default: false,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    dialogOptions() {
      const options = merge({}, this.$props);
      delete options.value;
      delete options.title;
      delete options.loading;
      delete options.showClose;
      delete options.showFooter;
      return options;
    },
    cardOptions() {
      const options = { loading: this.loading };
      return options;
    },
  },
  methods: {
    save() {
      this.$emit('save');
    },
  },
};
</script>
