import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    drawer: false,
    items: [
      {
        text: 'Home',
        href: '/#/',
      },
    ],
    auth: {},
  },
  getters: {
    links: (state) => state.items,
    auth: (state) => state.auth,
  },
  mutations: {
    setDrawer: (state, payload) => { state.drawer = payload; },
    toggleDrawer: (state) => { state.drawer = !state.drawer; },
  },
  actions: {
  },
});
